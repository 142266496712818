.tg-game {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.tg-game-header-container {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.tg-game-primary-container {
    height: 80%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 1em;
}
