.status-text {
    color: white;
    font-size: normal;
    text-shadow: 1px 1px 1px black;
}

.goal-cities-container {
    display: flex;
    font-size: large;
    font-weight: 700;
}
