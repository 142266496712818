.tg-city {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: small;
    font-weight: 900;
}

.tg-city-dot {
    width: 10px;
    height: 10px;
    border: 1px solid black;
    border-radius: 50%;
}
